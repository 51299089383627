import axios from "axios"

import getConfig from "highline/config/application"
import Rollbar from "rollbar"

const logErr = ({ err = null, logger = null } = { err: null, logger: null }) => {
  if (err) {
    const errorMessage = `Power Reviews failed - ${err.toString()}`
    if (process.env.NODE_ENV === "development") {
      console.error(err)
    }

    if (typeof logger === "function") {
      Rollbar.error(errorMessage, err)
    }
  }
}

const defaultOptions = {
  include_media: false,
  include_merchant_responses: false,
  include_syndication: false,
}

const defaultReviewsByIdPayload = {
  reviewId: "",
  options: defaultOptions,
}

const defaultFaceoffPayload = {
  productId: "",
  fallbackReviewId: "",
  options: defaultOptions,
}

const { powerReviewsApiUrl, powerReviewsMerchantId, powerReviewsApiKey } = getConfig()
const baseUrl = `${powerReviewsApiUrl}/m/${powerReviewsMerchantId}/l/en_US`

export async function fetchPowerReviews(productId) {
  return axios.get(
    `${baseUrl}/product/${productId}/reviews?apikey=${powerReviewsApiKey}&_noconfig=true`
  )
}

export function fetchPowerReviewByReviewId({
  reviewId = defaultReviewsByIdPayload.reviewId,
  options = defaultOptions,
} = defaultReviewsByIdPayload) {
  try {
    if (reviewId) {
      const params = new URLSearchParams()
      params.append("apikey", powerReviewsApiKey)
      params.append("_noconfig", true)

      Object.entries(options).forEach(([key, value]) => {
        params.append(key, value)
      })

      return axios.get(`${baseUrl}/review/${reviewId}?${params.toString()}`)
    }
  } catch (error) {
    logErr({ err, logger: Rollbar.error })
    return err
  }
}

export async function fetchPositiveFaceOffReview({
  productId = defaultFaceoffPayload.productId,
  fallbackReviewId = defaultFaceoffPayload.fallbackReviewId,
  options = defaultOptions,
} = defaultFaceoffPayload) {
  try {
    if (fallbackReviewId) {
      return fetchPowerReviewByReviewId({ reviewId: fallbackReviewId })
    }

    if (!productId) {
      throw new Error(`Neither productId nor fallbackReviewId provided`)
    }

    if (productId) {
      const params = new URLSearchParams()
      params.append("apikey", powerReviewsApiKey)
      params.append("_noconfig", true)

      Object.entries(options).forEach(([key, value]) => {
        params.append(key, value)
      })

      const reviews = await axios.get(
        `${baseUrl}/product/${productId}/reviews?${params.toString()}`
      )
      const faceoffPositiveReview = reviews?.data?.results?.[0]?.rollup?.faceoff_positive ?? null

      if (!faceoffPositiveReview?.ugc_id) {
        throw new Error(`No results found for the product id ${productId}`)
      }

      const reviewId = faceoffPositiveReview.ugc_id
      return fetchPowerReviewByReviewId({ reviewId })
    }
  } catch (err) {
    logErr({ err, logger: Rollbar.error })
    return err
  }
}
