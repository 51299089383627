import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import Rollbar from "rollbar"

import { fetchPowerReviews } from "highline/api/power_reviews"
import ProductReviewsStarRating from "highline/components/contentful/product-reviews/contentful_product_reviews_star_rating.js"

import styles from "highline/styles/components/product_reviews_star_rating.module.css"
import classNames from "classnames"

export const defaultRating = {
  floor: 0,
  ceiling: 5,
  rating: -1,
  count: -1,
}

const logErr = ({ err = null, logger = null } = { err: null, logger: null }) => {
  if (err) {
    const errorMessage = `Power Reviews: processReviews failed - ${err.toString()}`
    if (process.env.NODE_ENV === "development") {
      console.error(err)
    }

    if (typeof logger === "function") {
      logger(errorMessage, err)
    }
  }
}

export const processPowerReviews = ({ data = null } = { data: null }) => {
  try {
    return data
      ? {
          ...defaultRating,
          rating: data?.rollup?.average_rating ?? -1,
          count: data?.rollup?.rating_count ?? -1,
        }
      : defaultRagting
  } catch (err) {
    logErr({ err, logger: Rollbar.error })
  }
}

const ProductReviewsStarRatingContainer = ({ productId }) => {
  const [reviewsData, setReviewsData] = useState({ loading: false, data: null, error: null })
  const [productRating, setProductRating] = useState(defaultRating)

  const fetchReviews = useCallback(
    async ({ fetcher = null, params = {} } = { fetcher: null, params: {} }) => {
      if (typeof fetcher === "function" && params?.productId) {
        try {
          setReviewsData({ ...reviewsData, loading: true })
          const data = await fetcher(params?.productId)
          setReviewsData({ ...reviewsData, data, loading: false })
          return
        } catch (err) {
          setReviewsData({ data: null, error: err, loading: false })
          logErr({ err, logger: Rollbar.error })
        }
      }
      setReviewsData({ data: null, error: new Error("No reviewId provided."), loading: false })
      return
    },
    [reviewsData]
  )

  const processReviews = useCallback(
    ({ dataTransformer = null, data = null } = { processer: null, data: null }) => {
      if (typeof dataTransformer === "function" && data) {
        try {
          return dataTransformer({ data })
        } catch (err) {
          logErr({ err, logger: Rollbar.error })
        }
      }
      return defaultRating
    },
    [defaultRating]
  )

  useEffect(() => {
    const { loading, data, error } = reviewsData

    if (error || !productId) {
      return
    }

    // FETCHING
    const shouldFetchReviews = !loading && !data && !error && !!productId
    if (shouldFetchReviews) {
      fetchReviews({ fetcher: fetchPowerReviews, params: { productId } })
    }

    // PROCESSING REVIEWS
    const shouldProcessReviews = !loading && !error && !!data
    if (shouldProcessReviews) {
      const results = data?.data?.results?.[0] ?? null
      const rating = processReviews({
        dataTransformer: processPowerReviews,
        data: results,
      })
      setProductRating(rating)
    }
  }, [productId, reviewsData, fetchPowerReviews, fetchReviews])

  if (!productId || !reviewsData?.data || reviewsData.error) {
    return null
  }

  const { floor, ceiling, rating, count } = productRating
  const isValidRating = rating >= floor
  const ratingLabel = count >= 0 ? `${count} Reviews` : ""

  if (!isValidRating && !ratingLabel) {
    return null
  }

  return (
    <p className={classNames(styles.starRatingContainer)}>
      <ProductReviewsStarRating ceiling={ceiling} floor={floor} rating={rating} />{" "}
      <span>{ratingLabel}</span>
    </p>
  )
}

ProductReviewsStarRatingContainer.propTypes = {
  productId: PropTypes.string,
}

ProductReviewsStarRatingContainer.defaultProps = {
  productId: "",
}

export default ProductReviewsStarRatingContainer
