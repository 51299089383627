import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import NavGroup from "highline/components/nav_group"
import NavItem from "highline/components/nav_item"
import styles from "highline/styles/components/tab_group.module.css"

const TabGroup = ({
  children,
  className,
  layout,
  value,
  onTabChange,
  trackTabClick,
  navItemClassName,
  removeBtnTabIndex,
  hasSticky,
}) => {
  const [activeTab, setActiveTab] = useState("")
  const [uniqueIdKey, setUniqueIdKey] = useState("")
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    let activeTabCustomKey = ""
    const tabsTransform = React.Children.toArray(children).map((tab, idx) => {
      if (tab.props.title === value) {
        activeTabCustomKey = `${tab.props.title}-${idx}`
      }

      return React.cloneElement(tab, {
        customKey: `${tab.props.title}-${idx}`,
      })
    })

    if (!activeTabCustomKey) {
      activeTabCustomKey = tabsTransform[0]?.props.customKey
    }

    setUniqueIdKey(`tabbed-nav-key-${Math.random()}`)
    setTabs(tabsTransform)
    setActiveTab(activeTabCustomKey)
  }, [children, value])

  const handleTabChange = (name) => {
    setActiveTab(name)
    onTabChange(name)
    trackTabClick(name)
  }

  const currentTab = tabs.find((tab) => {
    return tab.props.customKey === activeTab
  })

  return (
    <div className={classNames("component", "tabbed-nav-component", styles.component, className)}>
      {tabs && tabs.length > 1 && (
        <NavGroup
          layout={layout}
          activeItemName={activeTab}
          onChange={handleTabChange}
          hasSticky={hasSticky}
        >
          {tabs.map((tab, index) => (
            <NavItem
              key={tab.props.customKey}
              name={tab.props.customKey}
              className={navItemClassName}
            >
              <button
                role="tab"
                aria-selected={activeTab === tab.props.customKey}
                aria-controls={`${uniqueIdKey}-section-${index}`}
                id={`${uniqueIdKey}-tab-${index}`}
                {...(removeBtnTabIndex && { tabIndex: "-1" })}
              >
                {tab.props.titleContent ? tab.props.titleContent : tab.props.title}
              </button>
            </NavItem>
          ))}
        </NavGroup>
      )}
      {tabs && (
        <div
          id={`${uniqueIdKey}-section-${activeTab}`}
          aria-labelledby={`${uniqueIdKey}-tab-${activeTab}`}
        >
          {currentTab}
        </div>
      )}
    </div>
  )
}

TabGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  navItemClassName: PropTypes.string,
  value: PropTypes.string,
  layout: PropTypes.oneOf(["sliding", "tabbed", "swatch"]),
  onTabChange: PropTypes.func,
  trackTabClick: PropTypes.func,
  removeBtnTabIndex: PropTypes.bool,
  hasSticky: PropTypes.bool,
}

TabGroup.defaultProps = {
  layout: "sliding",
  navItemClassName: "",
  onTabChange: () => {},
  trackTabClick: () => {},
  removeBtnTabIndex: false,
}

export default TabGroup
