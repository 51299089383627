import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { StarIcons } from "highline/components/icons"

import styles from "highline/styles/components/contentful/contentfu_product_reviews_star_rating.module.css"

export const StarIconContainer = ({ children }) => {
  if (!children) {
    return null
  }

  return <span className={classNames(styles.starIconContainer)}>{children}</span>
}

StarIconContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

StarIconContainer.defaultProps = {
  children: null,
}

const ContentfulProductReviewsStarRating = ({ ceiling, floor, rating, size }) => {
  const customIconSizeClasName = "customIconSize"

  if (rating < floor) {
    return null
  }

  const stars = [...Array(ceiling).keys()].map((index) => {
    if (index <= Math.trunc(rating) - 1) {
      return (
        <StarIconContainer key={`solid-${index}`}>
          <StarIcons.Solid
            className={classNames(customIconSizeClasName, styles.starIcon)}
            size={size}
          />
        </StarIconContainer>
      )
    } else if (Number.isInteger(rating)) {
      return (
        <StarIconContainer key={`light-${index}`}>
          <StarIcons.Light
            className={classNames(customIconSizeClasName, styles.starIcon)}
            size={size}
          />
        </StarIconContainer>
      )
    }
    return (
      <StarIconContainer key={`solid-${index}`}>
        <StarIcons.Light
          className={classNames(customIconSizeClasName, styles.starIcon)}
          size={size}
        />
        <StarIcons.Half className={classNames(customIconSizeClasName)} key={`solid-${index}`} />
      </StarIconContainer>
    )
  })

  return <span data-testid="contentful_product_reviews_star_rating">{stars}</span>
}

ContentfulProductReviewsStarRating.propTypes = {
  ceiling: PropTypes.number,
  className: PropTypes.string,
  floor: PropTypes.number,
  rating: PropTypes.number,
  size: PropTypes.string,
}

ContentfulProductReviewsStarRating.defaultProps = {
  ceiling: 5,
  className: "",
  floor: 4,
  rating: 0,
  size: "sm",
}

export default ContentfulProductReviewsStarRating
